import { fetchFingerprintPayload } from '@play-co/fingerprint';
import { type PlatformWeb, analytics, GCInstant } from '@play-co/gcinstant';
import { configureExtensions } from '@play-co/gcinstant/replicantExtensions';
import {
    type ClientReplicant,
    type ReplicantFromConfig,
    createClientReplicant,
    createOfflineReplicant,
    createWebPlayableReplicant,
    parseReplicantPlatform,
} from '@play-co/replicant';

import { onError } from './app/lib/util/errors';
import { openOA } from './lib/util/officialAccount';
import { computeAmplitudeAnalytics } from './plugins/coreTech/HardwareAnalytics';
import { isFullTutorialComplete } from './replicant/components/tutorial';
import config from './replicant/config';

// constants
//-----------------------------------------------------------------------------
const backendPlatform = 'line';

let resolveReplicantClient: ((replicant: ReplicantClient) => void) | null = null;
const replicantClientPromise = new Promise<ReplicantClient>((resolve) => {
    resolveReplicantClient = resolve;
});

// https://docs.dev.gc-internal.net/gcinstant-replicant-extensions/index.html#usage
configureExtensions({
    analytics,
    gcinstant: GCInstant,
    replicantClientPromise,
});

type ReplicantClient = ClientReplicant<ReplicantFromConfig<typeof config>>;

function assignManualTests(): void {}

function getOverriddenPlayerID(): string | null {
    if (!process.env.REPLICANT_OFFLINE) {
        return null;
    }

    const param = window.location.search
        .replace(/\?/g, '')
        .split('&')
        .find((x) => x.startsWith('profile='));

    return param ? param.substr('profile='.length) : null;
}

function overrideOfflinePlayerID() {
    if (!process.env.REPLICANT_OFFLINE) {
        return;
    }

    const overriddenID = getOverriddenPlayerID();

    if (overriddenID) {
        GCInstant.playerID = overriddenID;
        GCInstant.playerName = `Player ${overriddenID}`;
    }
}

async function createReplicantClient(): Promise<ReplicantClient> {
    // override to use line backend while using web frontend
    const platform = parseReplicantPlatform(backendPlatform);
    // const platform = parseReplicantPlatform(process.env.PLATFORM);

    if (!platform) {
        throw Error(`Invalid Replicant platform: ${backendPlatform}`);
    }

    if (platform === 'web') {
        if (process.env.REPLICANT_OFFLINE) {
            return createOfflineReplicant(config, 'offline', {
                platform: 'web',
            });
        }
        return createWebPlayableReplicant(config, {
            platform,
            endpoint: process.env.REPLICANT_ENDPOINT,
        });
    }

    // create web client with line platform
    return createClientReplicant(config, GCInstant.playerID, {
        platform,
        endpoint: process.env.REPLICANT_ENDPOINT,
        signature: GCInstant.playerSignature,
        obtainSignature: async () => GCInstant.refreshPlayerSignature(),
    });
}

let loadPromiseResolve: ((value: void | PromiseLike<void>) => void) | null = null;

window.CoreTech = {
    platform: GCInstant,
    replicant: null as any,
    loadPromise: new Promise<void>((resolve) => {
        loadPromiseResolve = resolve;
    }),
};

// async iife
void (async () => {
    try {
        await GCInstant.initializeAsync({
            appID: process.env.APP_NAME,
            shortName: process.env.SHORT_NAME,
            isDevelopment: process.env.NODE_ENV === 'development',
            version: VERSION,
            disableWebPush: true,
            disableAutomaticTosPopup: true, // TODO enable once decision is made
            webSkipLineUserInfoFetch: true, // Remove if profile is needed (name+pic). Skipping it results in 1 less api call
        });
    } catch (error) {
        // user cancelled login, error reported in amplitude from gcinstant under PlatformInitFailed
        if (error.code === 'UNAUTHORIZED') {
            // User cancelled login, stop everything.
            openOA();
            return;
        }
        // UNEXPECTED ERROR
        throw error;
    }

    GCInstant.setFallbackPayloadGetter(fetchFingerprintPayload);

    overrideOfflinePlayerID();

    const replicant = await createReplicantClient();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    resolveReplicantClient(replicant);

    if (GCInstant.platformID === 'web') {
        (GCInstant as PlatformWeb).setPlayerID(replicant.userId);
    }

    // Set up ad revenue tracking on FB, see https://docs.dev.gc-internal.net/gcinstant/ads/:
    if (GCInstant.platformID === 'fb') {
        GCInstant.setECPM(replicant.extras.getECPM());
    }

    // skip warning if local dev
    if (!process.env.AMPLITUDE && !process.env.REPLICANT_OFFLINE && GCInstant.platformID !== 'mock') {
        console.error('Set AMPLITUDE in ./configs to enable Amplitude integration.');
    }

    // initialise analytics
    const deviceProps = computeAmplitudeAnalytics();

    const userProps = {
        ...deviceProps,
        realtimeTutorialFinished: isFullTutorialComplete(replicant.state),
    };
    // Set custom user properties
    analytics.setUserProperties(userProps);

    replicant.setOnError(onError);

    assignManualTests();

    window.CoreTech.replicant = replicant;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    loadPromiseResolve();
})();
