import { analytics } from '@play-co/gcinstant';
import { ReplicantError, ReplicantErrorSubCode } from '@play-co/replicant';

import app from '../../../index.entry';
import { coreTech } from '../../../plugins/coreTech/CoreTech';
import { getGameUrl } from '../../../replicant/util/envTools';
import { captureGenericError, captureReplicantError } from '../sentry';

async function gameRecover(refreshSignature: boolean) {
    await coreTech.refreshUserState(refreshSignature);

    app.game.startIdleStepper();
    // open home screen
    app.nav.open('homeScreen', { gardenState: app.server.state.garden });
}

export async function onReplicationError(errorSubCode: ReplicantErrorSubCode) {
    await app.showAlert('[popupErrorGeneralMessage]', '[popupErrorGeneralTitle]');

    if (errorSubCode === ReplicantErrorSubCode.token_expired) {
        await coreTech.platform.refreshPlayerSignature();
    }

    await gameRecover(errorSubCode === ReplicantErrorSubCode.token_expired);
}

async function onSessionDesyncError() {
    await app.showAlert('[popupErrorDesyncMessage]', '[popupErrorDesyncTitle]');
    await gameRecover(false);
}

export async function onNetworkError() {
    await app.showAlert('[popupErrorConnectionMessage]', '[popupErrorConnectionTitle]');
    try {
        await coreTech.replicant.retryLastRequest();
    } catch (e) {
        // Do not propagate error as the network error handler will be invoked again.
    }
}

async function onVersionError() {
    await app.showAlert('[popupErrorVersionMessage]', '[popupErrorVersionTitle]');
    reload();
}

export function onError(error: ReplicantError) {
    app.game.pauseIdleStepper();
    switch (error.code) {
        case 'unknown_error':
        case 'replication_error':
        case 'authorization_error':
        case 'server_error':
            captureReplicantError(error);
            return onReplicationError(error.subCode);
        case 'network_error':
            return onNetworkError();
        case 'session_desync_error':
            analytics.pushError('SessionDesyncError', error);
            return onSessionDesyncError();
        case 'version_error':
            captureReplicantError(error);
            return onVersionError();
        default: {
            // This indicates a replicant bug. Log it in sentry.
            captureGenericError(`Unknown replicant error! '${error.code}'`, error);
            // Treat this as a replication error.
            return onReplicationError(null);
        }
    }
}

function reload() {
    // Load from game url so we get latest version
    window.location.href = getGameUrl();
}
