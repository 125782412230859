import gameConfig from '../defs/gameConfig';

// types
//-----------------------------------------------------------------------------
/*
    base social entity
*/
export interface Person {
    // general
    id: string;
    name: string;
    image?: string;
    // flags
    isBot?: boolean;
}

// constants
//-----------------------------------------------------------------------------
export const undefinedPerson: Person = {
    id: '0',
    name: '?',
    image: gameConfig.person.defaultImage,
};
